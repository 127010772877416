/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import React from 'react';
import classNames from 'classnames';
import Typography from '@common_typography';
import { formatPrice } from '@helper_currency';
import { makeStyles } from '@material-ui/core/styles';
import useStyles from '@modules/commons/PriceFormat/style';

const useLocalStyles = makeStyles(() => ({
    priceContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    price: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        '@media (max-width: 767px )': {
            '& .price__old, .price__final': {
                fontSize: 10,
            },
        },
    },
    priceWrapper: {
        marginRight: 25,
    },
    badge: {
        position: 'absolute',
        top: -7,
        borderRadius: '50px',
        textAlign: 'center',
        '&.badge__container': {
            right: '0',
            height: '40px',
            width: '40px',
        },
        '& .badge__value': {
            lineHeight: '38px',
            color: '#fff',
            fontSize: '10px',
        },
        '@media (max-width: 767px)': {
            '& .price_text': {
                fontSize: '2.5vw',
            },
            '&.badge__container': {
                right: '-30%',
                top: '-10%',
                width: '20px',
                height: '20px',
            },
            '& .badge__value': {
                fontSize: '8px',
                lineHeight: '20px',
            },
        },
    },
}));

const getLowestTierPrice = (tier_price) => {
    let lowestTierPrice;
    let min = Number.POSITIVE_INFINITY;
    tier_price.forEach((price) => {
        if (price.final_price.value < min) {
            min = price.final_price.value;
            lowestTierPrice = price;
        }
    });

    return lowestTierPrice;
};

const AsLowAsText = () => {
    const styles = useStyles();
    return (
        <Typography
            variant="span"
            size="8"
            letter="uppercase"
            className={classNames(styles.noMargin, 'price_text')}
        >
            as low as:
            {' '}
        </Typography>
    );
};

const SimpleProductTypePrice = ({
    priceRange,
    priceTiers,
    specialFromDate,
    specialToDate,
    vendorCost,
    vendorSpecialPrice,
    flashSalePrice,
    flashSaleFrom,
    flashSaleTo,
    showDiscountBadge = true,
    defaultColor,
    currencyCache,
    // vendorFlashSalePercent,
    // vendorFlashSaleDiscountAmount,
}) => {
    const styles = useStyles();
    const localStyles = useLocalStyles();
    const regularPrice = priceRange.minimum_price.regular_price;
    const finalPrice = priceRange.minimum_price.final_price;
    let validSpecial = true;
    const nowTime = new Date(Date.now()).getTime();

    const renderOldPrice = (p) => (
        <Typography
            variant="span"
            letter="capitalize"
            className={classNames(styles.noMargin, styles.oldPrice, 'price__old')}
        >
            <strike>{formatPrice(p, regularPrice.currency, currencyCache)}</strike>
        </Typography>
    );

    const renderNewPrice = (p, customStyle = {}) => (
        <Typography
            variant="span"
            type="bold"
            letter="capitalize"
            className={classNames(styles.noMargin, styles.finalPrice, 'price__final', customStyle)}
        >
            {formatPrice(p, regularPrice.currency, currencyCache)}
        </Typography>
    );

    const renderDiscountBadge = (regPrice, discPrice, backgroundColor = '#f32e3b') => {
        const tampPercentage = (((regPrice - discPrice) / regPrice) * 100).toFixed(2);
        const percentage = parseFloat(tampPercentage).toString();
        return (
            <div className={classNames(localStyles.badge, 'badge__container')} style={{ backgroundColor }}>
                <div className="badge__value">
                    {percentage}
                    %
                </div>
            </div>
        );
    };

    // priority 1: Flashsale Price
    if (
        flashSalePrice
        && flashSaleFrom
        && flashSaleTo
        && nowTime >= new Date(flashSaleFrom).getTime()
        && nowTime <= new Date(flashSaleTo).getTime()
    ) {
        // if (vendorFlashSalePercent || vendorFlashSaleDiscountAmount) {
        //     return (
        //         <div className={localStyles.priceContainer}>
        //             <div
        //                 className={classNames(localStyles.price, 'priceShowDisccount')}
        //                 style={{ flexDirection: showDiscountBadge ? 'column' : 'row' }}
        //             >
        //                 <div className={localStyles.priceWrapper}>
        //                     {renderOldPrice(vendorCost)}
        //                     {!showDiscountBadge && <div style={{ padding: '0px 4px' }}>{' '}</div>}
        //                     {renderNewPrice(flashSalePrice, defaultColor ? null : styles.finalPriceRed)}
        //                 </div>
        //                 {showDiscountBadge && renderDiscountBadge(vendorCost, vendorSpecialPrice)}
        //             </div>
        //         </div>
        //     );
        // }
        return (
            <div className={localStyles.priceContainer}>
                <div className={classNames(localStyles.price, 'priceShowDisccount')} style={{ flexDirection: showDiscountBadge ? 'column' : 'row' }}>
                    {vendorCost <= flashSalePrice
                        ? (
                            <div className={localStyles.priceWrapper}>
                                {renderNewPrice(flashSalePrice, defaultColor ? null : styles.finalPriceRed)}
                            </div>
                        ) : (
                            <>
                                <div className={localStyles.priceWrapper}>
                                    {renderOldPrice(vendorCost)}
                                    {!showDiscountBadge && <div style={{ padding: '0px 4px' }}>{' '}</div>}
                                    {renderNewPrice(flashSalePrice, defaultColor ? null : styles.finalPriceRed)}
                                </div>
                                {showDiscountBadge && renderDiscountBadge(vendorCost, flashSalePrice)}
                            </>
                        )}
                </div>
            </div>
        );
    }

    // priority 2: Vendor Special Price
    if (vendorCost && vendorSpecialPrice && (vendorCost != vendorSpecialPrice)) {
        return (
            <div className={localStyles.priceContainer}>
                <div className={classNames(localStyles.price, 'priceShowDisccount')} style={{ flexDirection: showDiscountBadge ? 'column' : 'row' }}>
                    <div className={localStyles.priceWrapper}>
                        {renderOldPrice(vendorCost)}
                        {!showDiscountBadge && <div style={{ padding: '0px 4px' }}>{' '}</div>}
                        {renderNewPrice(vendorSpecialPrice, defaultColor ? null : styles.finalPriceRed)}
                    </div>
                    {showDiscountBadge && renderDiscountBadge(vendorCost, vendorSpecialPrice)}
                </div>
            </div>
        );
    }

    // priority 3: Vendor Price
    if (vendorCost) return renderNewPrice(vendorCost);

    if (specialFromDate && specialFromDate !== null) {
        const startTime = new Date(specialFromDate).getTime();
        if (nowTime < startTime) validSpecial = false;
    }
    if (specialToDate && specialToDate !== null) {
        const endTime = new Date(specialToDate).getTime();
        if (nowTime > endTime) validSpecial = false;
    }
    // if has tierprice
    if (priceTiers && priceTiers.length) {
        const lowestPriceTier = getLowestTierPrice(priceTiers);
        // if there are several tierprices
        if (priceTiers.length > 1) {
            // case 1: if has no discount
            if (regularPrice.value === finalPrice.value) {
                return (
                    <>
                        {/* case 1 */}
                        <Typography variant="span" type="bold" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                            {formatPrice(finalPrice.value, finalPrice.currency, currencyCache)}
                        </Typography>
                        <AsLowAsText />
                        <Typography variant="span" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                            {formatPrice(lowestPriceTier.final_price.value, lowestPriceTier.final_price.currency, currencyCache)}
                        </Typography>
                    </>
                );
            }
            // case 2: if final price is lowest than lowest tier price
            if (finalPrice.value < lowestPriceTier.final_price.value) {
                return (
                    <>
                        {/* case 2 */}
                        <Typography variant="span" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                            <strike>{formatPrice(regularPrice.value, regularPrice.currency, currencyCache)}</strike>
                        </Typography>
                        <Typography variant="span" type="bold" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                            {formatPrice(finalPrice.value, finalPrice.currency, currencyCache)}
                        </Typography>
                    </>
                );
            }
            // case 3: if final price is higher than lowest tier price
            return (
                <>
                    {/* case 3 */}
                    <Typography variant="span" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                        <strike>{formatPrice(regularPrice.value, regularPrice.currency, currencyCache)}</strike>
                    </Typography>
                    <Typography variant="span" type="bold" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                        {formatPrice(finalPrice.value, finalPrice.currency, currencyCache)}
                    </Typography>
                    <AsLowAsText />
                    <Typography variant="span" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                        {formatPrice(lowestPriceTier.final_price.value, lowestPriceTier.final_price.currency, currencyCache)}
                    </Typography>
                </>
            );
        }

        // else:
        // if there is only one tierprice
        const firstTierPrice = priceTiers[0];
        // case 4: if there is no discount and has tier price
        if (regularPrice.value === finalPrice.value) {
            return (
                <>
                    {/* case 4 */}
                    <Typography variant="span" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                        <strike>{formatPrice(regularPrice.value, regularPrice.currency, currencyCache)}</strike>
                    </Typography>
                    <Typography variant="span" type="bold" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                        {formatPrice(firstTierPrice.final_price.value, firstTierPrice.final_price.currency, currencyCache)}
                    </Typography>
                </>
            );
        }
        // case 5: if final price is lower than tier price
        if (finalPrice.value < firstTierPrice.final_price.value) {
            return (
                <>
                    {/* case 5 */}
                    <Typography variant="span" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                        <strike>{formatPrice(regularPrice.value, regularPrice.currency, currencyCache)}</strike>
                    </Typography>
                    <Typography variant="span" type="bold" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                        {formatPrice(finalPrice.value, finalPrice.currency, currencyCache)}
                    </Typography>
                </>
            );
        }
        // case 6: if tier price is lower than final price and tier price qty is 1
        if (firstTierPrice.quantity === 1 || finalPrice.value === firstTierPrice.final_price.value) {
            return (
                <>
                    {/* case 6 */}
                    <Typography variant="span" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                        <strike>{formatPrice(regularPrice.value, regularPrice.currency, currencyCache)}</strike>
                    </Typography>
                    <Typography variant="span" type="bold" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                        {formatPrice(firstTierPrice.final_price.value, firstTierPrice.final_price.currency, currencyCache)}
                    </Typography>
                </>
            );
        }
        // case 7: if tier price is lower than final price but tier price qty > 1
        return (
            <>
                {/* case 7 */}
                <Typography variant="span" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                    <strike>{formatPrice(regularPrice.value, regularPrice.currency, currencyCache)}</strike>
                </Typography>
                <Typography variant="span" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                    {formatPrice(finalPrice.value, finalPrice.currency, currencyCache)}
                </Typography>
                <AsLowAsText />
                <Typography variant="span" type="bold" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                    {formatPrice(firstTierPrice.final_price.value, firstTierPrice.final_price.currency, currencyCache)}
                </Typography>
            </>
        );
    }

    // else:
    // if there is no tier price

    // case 8: if there is no discount
    if (regularPrice.value === finalPrice.value) {
        return (
            <>
                {/* case 8 */}
                <Typography variant="span" type="bold" letter="uppercase" className={classNames(styles.noMargin, 'price_text')}>
                    {formatPrice(finalPrice.value, finalPrice.currency, currencyCache)}
                </Typography>
            </>
        );
    }
    // case 9: if has discount
    return (
        <>
            {/* case 9 */}
            {
                validSpecial ? (
                    <Typography
                        variant="span"
                        letter="capitalize"
                        className={classNames(styles.noMargin, styles.oldPrice)}
                    >
                        <strike>{formatPrice(regularPrice.value, regularPrice.currency, currencyCache)}</strike>
                    </Typography>
                ) : null
            }
            <Typography
                variant="span"
                type="bold"
                letter="capitalize"
                className={classNames(styles.noMargin, styles.finalPriceRed)}
            >
                {
                    validSpecial ? formatPrice(finalPrice.value, finalPrice.currency, currencyCache)
                        : formatPrice(regularPrice.value, regularPrice.currency, currencyCache)
                }
            </Typography>
        </>
    );
};

export default SimpleProductTypePrice;
